<template>
  <div id="app">
      <Main msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import Main from './components/Main.vue'

export default {
  name: 'App',
  components: {
    Main
  }
}
</script>

<style lang="scss">
body {
  // background-color: #eec0c6;
  // background-image: linear-gradient(315deg, #eec0c6 0%, #7ee8fa 74%);
}
#app {
  width: 100%;
  height: 100%;
}
</style>
