<template>
  <div :class="`main ${started ? 'started' : ''}`">
    <div class="start-wrap" v-if="!started">
      <img src="/img/opening.svg" class="start-wrap-bg" alt="" />

      <transition name="bounce">
        <button
          @click="started = true"
          class="start-btn img-btn"
          v-if="isLoaded"
        >
          <img src="img/start_btn.svg" alt="" />
        </button>
      </transition>

      <div v-if="!isLoaded" class="loading">
        <h2 class="load-text">
          <span class="blue">ט</span><span class="yellow">ו</span
          ><span class="green">ע</span><span class="red">ן</span>
        </h2>
        <img src="img/cog.svg" alt="" />
        <div class="pin-hold">
          <div class="pin"></div>
        </div>
      </div>
    </div>

    <!-- <button class="dialog-btn">i</button> -->
    <!-- <div class="dialog"></div> -->
    <button @click="spin" class="spin-btn" v-if="started">
      <!-- <img src="/img/start_btn2.svg" alt="" /> -->
    </button>

    <Score :score="score" :highScore="highScore" v-if="started" />

    <!-- <div class="instructions" v-if="instructOpen && started">
      <div class="inner">
        <img src="/img/touch_icon.svg" class="press-img" alt="press" />
        <h3>או</h3>
        <img src="/img/screen_rot.svg" class="shake-img" alt="rotate" />
      </div>

    </div> -->
    <!-- check if mobile! -->

    <!-- <h3 class="score high-score" dir="auto" v-if="started">
      שיא: {{ highScore }}
    </h3> -->

    <a-scene
      vr-mode-ui="enabled: false"
      loading-screen="enabled: false"
      v-show="started"
    >
      <a-assets @loaded="modelsLoaded">
        <a-asset-item id="base" src="models/base.glb"></a-asset-item>
        <a-asset-item id="spinner" src="models/spinner.glb"></a-asset-item>
      </a-assets>
      <a-gltf-model scale="0.3 0.3 0.3" ref="base" src="#base">
        <a-cylinder
          ref="spinCyl"
          :animation="`property: rotation; to: 0 ${spinCyl} 0; dur: 500`"
          @animationcomplete="startEndAudio"
          @animationbegin="startEndAudio"
          position="0 8.9 0"
          radius="0"
          height="1"
          color="red"
          visible="true"
        >
          <a-gltf-model
            position="0 0 0"
            rotation="0 90 0"
            ref="spinner"
            src="#spinner"
          >
          </a-gltf-model>
        </a-cylinder>
      </a-gltf-model>
      <a-entity camera="fov: 120" position="0 2 3"></a-entity>
    </a-scene>
  </div>
</template>

<script>
import Shake from "../assets/shake/shake.js";
import Score from "../components/Score.vue";
export default {
  name: "Main",
  components: { Score },
  data: function () {
    return {
      isLoaded: false,
      started: false,
      score: 0,
      spinCyl: 0,
      sound: null,
      flipBg: false,
      isAudio: false,
      instructOpen: true,
      highScore: 0,
    };
  },
  methods: {
    startEndAudio: function () {
      this.soundStartEnd.play();
    },
    modelsLoaded: function () {
      this.isLoaded = true;
    },
    animComplete: function () {
      console.log("animComplete");
    },
    spin: function () {
      this.score++;
      this.spinCyl += Math.random() * 530;
      this.sound.currentTime = 0;
      this.sound.play();
      this.flipBg = !this.flipBg;
      window.navigator.vibrate(70);
    },
    rndColor: function () {
      return new THREE.Color(
        "#" + Math.floor(Math.random() * 16777215).toString(16)
      );
    },
    colorChange: function () {
      let spinner = this.$refs.spinner.object3D.children[0].children[0]
        .children[0];
      // let base = this.$refs.base.object3D.children[0].children[0].children[0]
      //   .children[0].children[0];
      // console.log(base);
      spinner.material.color = this.rndColor();
      // let newColor = this.rndColor();
      // base.material = new THREE.MeshBasicMaterial({
      //   color: newColor,
      // });
      // base.material.needsUpdate = true;
    },
  },
  watch: {
    score: function () {
      if (this.score > this.highScore) this.highScore = this.score;
      if (this.score % 10 === 0) {
        this.colorChange();
      }
    },
  },
  mounted() {
    this.sound = new Audio("audio/noise_2.mp3");
    this.soundStartEnd = new Audio("audio/noise_se.mp3");
    let spinCyl = this.$refs.spinCyl.object3D.rotation.y;
    let storage = window.localStorage;
    if (storage.getItem("highScore") === null) {
      console.log(storage);
      storage.setItem("highScore", 0);
    } else {
      this.highScore = parseInt(storage.getItem("highScore"));
    }
    window.addEventListener("unload", () => {
      storage.setItem("highScore", this.highScore);
    });

    var myShakeEvent = new Shake({
      threshold: 2,
      timeout: 100,
    });

    myShakeEvent.start();
    window.addEventListener(
      "shake",
      () => {
        this.spin();
      },
      false
    );
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$blue: #08b5f5;
$yellow: #e9bd2c;
$green: #8ab669;
$red: #ff9396;
.blue {
  color: $blue;
}
.yellow {
  color: $yellow;
}
.green {
  color: $green;
}
.red {
  color: $red;
}
.main {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 100%;
  height: 100%;
  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: url("/img/bg.svg");
    background-size: cover;
  }
  &.started {
    &:before {
      background-color: #eec0c6;
      background-image: linear-gradient(315deg, #eec0c6 0%, #7ee8fa 74%);
    }
  }
  &.flipped {
    &:before {
      transform: scaleX(-1);
    }
  }
}
.dialog-btn {
  width: 60px;
  height: 60px;
  z-index: 5;
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: $blue;
  border: 5px solid $yellow;
  font-size: 30px;
  border-radius: 50%;
  color: white;
}
.spin-btn {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  &:focus {
    outline: none;
  }
}

.img-btn {
  cursor: pointer;
  background: none;
  border: none;
}
.loading {
  z-index: 5;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  .load-text {
    position: absolute;
    text-align: center;
    top: 28%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 10px;
    width: 100%;

    z-index: 1;
  }
  .pin-hold {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    animation: hit 0.5s infinite linear;
    background-color: white;
    z-index: 10;
    .pin {
      position: absolute;
      left: 50%;
      bottom: 0;
      height: 20px;
      width: 5px;
      border-radius: 2px;
      background-color: $blue;
    }
  }
  img {
    animation: rotation 2s infinite linear;
    height: 100px;
  }
  @keyframes hit {
    from {
      transform: rotate(-20deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}
.start-wrap {
  z-index: 5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 700px;
  max-height: 700px;
  .start-wrap-bg {
    width: 100%;
    height: auto;
  }
  .start-btn {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%);
    width: 40%;
    &:hover {
      opacity: 0.8;
    }
    img {
      width: 100%;
    }
  }
}
.bounce-enter-active {
  animation: bounce-in 0.7s;
}
.bounce-leave-active {
  // animation: bounce-in 0.7s reverse;
}
.dialog-enter-active {
  animation: scale-to 0.7s;
}
.dialog-leave-active {
  animation: scale-to 0.7s reverse;
}
@keyframes scale-to {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(0);
  }
}
@keyframes bounce-in {
  0% {
    transform: translate(-50%) rotate(-20deg);
  }
  25% {
    transform: translate(-50%) rotate(20deg);
  }
  50% {
    transform: translate(-50%) rotate(-20deg);
  }
  75% {
    transform: translate(-50%) rotate(20deg);
  }
  100% {
    transform: translate(-50%) rotate(0deg);
  }
}
.instructions {
  position: absolute;
  z-index: 2;
  bottom: 50px;
  left: 50%;
  padding: 10px;
  border-radius: 10px;
  transform: translate(-50%);
  background: rgba(255, 255, 255, 0.644);
  font-size: 1.5rem;
  .inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    h3 {
      margin: 0;
      margin-right: 20px;
    }
    .shake-img {
      width: 50px;
      height: 50px;
      animation: shake 1s infinite ease-in-out alternate;
    }
    .press-img {
      width: 50px;
      height: 50px;
      animation: press 1s infinite ease-in-out alternate;
    }

    @keyframes shake {
      from {
        transform: rotate(-20deg);
      }
      to {
        transform: rotate(20deg);
      }
    }
    @keyframes press {
      from {
        transform: scale(0.8);
      }
      to {
        transform: rotate(1);
      }
    }
  }
}
</style>
