<template>
  <div :class="`score`">
    <div class="score-wrap">
      <div class="txt-wrap">
        <h2 class="rounded high-score" dir="auto">שיא: {{ highScore }}</h2>
      </div>
      <div class="txt-wrap">
        <h2 class="rounded current-score" dir="auto">סיבובים: {{ score }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
import Shake from "../assets/shake/shake.js";
export default {
  name: "Score",
  props: ["score", "highScore"],
  data: function () {
    return {};
  },
  methods: {
    // startEndAudio: function () {
    //   this.soundStartEnd.play();
    // },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$blue: #08b5f5;
$yellow: #e9bd2c;
$green: #8ab669;
$red: #ff9396;
h2 {
}
.score-wrap {
  z-index: 5;
  position: absolute;
  padding: 10px;
  border-radius: 10px;
  top: 1%;
  right: 3%;
  display: flex;
  h2 {
    margin: 0;
    box-shadow: 4px 4px #86cedb;
    border: 4px solid $blue;
    border-radius: 10px;
    background: white;
    padding: 10px;
    margin-right: 10px;
    font-size: 1rem;
    &.high-score {
      font-size: 0.8rem;
      padding: 5px;

      border: 4px solid $yellow;
    }
  }
}
</style>
