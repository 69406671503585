import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";

Vue.config.productionTip = false;

Vue.config.ignoredElements = [
  "a-scene",
  "a-entity",
  "a-plane",
  "a-text",
  "a-sphere",
  "a-gltf-model",
  "a-box",
  "a-cylinder",
  "a-asset-item",
  "a-assets",
];

new Vue({
  render: (h) => h(App),
}).$mount("#app");
